<template>
  <div>
    <img src="../../assets/img/地图.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "map",
  props: {},
  data() {
    return {};
  },
  methods: {
    closeSelf() {
      this.$emit("closeme");
    }
  }
};
</script>
